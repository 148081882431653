import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  private authenticated = false;
  constructor(private router: Router) { }


  login(password: string) {
    if (password.trim().toLowerCase() === 'jesusislord') {
      this.authenticated = true;
      this.router.navigate(['healing']);
    } else {
      this.router.navigate(['home']);
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log(route);

    const authInfo = {
      authenticated: this.authenticated
    };

    if (!authInfo.authenticated) {
      this.router.navigate(['login']);
      return false;
    }

    return true;
  }
}
